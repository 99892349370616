import React from "react"
import { Link } from "gatsby"

const Pagination = ({ first, last, index, pageCount, pathPrefix }) => {
  return (
    <ul className="pagination">
      <li>
        {!first && (
          <Link
            to={index > 2 ? `${pathPrefix}/page/${index - 1}` : `${pathPrefix}`}
            className="prev"
          >
            &#60;
          </Link>
        )}
      </li>
      {index - 1 > 0 && (
        <li>
          <Link
            to={
              index - 1 > 1
                ? `${pathPrefix}/page/${index - 1}`
                : `${pathPrefix}`
            }
          >
            {index - 1}
          </Link>
        </li>
      )}
      <li>
        <Link
          to={index > 1 ? `${pathPrefix}/page/${index}` : `${pathPrefix}`}
          className="current"
        >
          {index}
        </Link>
      </li>
      {index + 1 <= pageCount && (
        <li>
          <Link to={`${pathPrefix}/page/${index + 1}`}>{index + 1}</Link>
        </li>
      )}
      <li>
        {!last && (
          <Link to={`${pathPrefix}/page/${index + 1}`} className="next">
            &#62;
          </Link>
        )}
      </li>
    </ul>
  )
}

export default Pagination
