import React, { Component } from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"

import Layout from "../components/layout"
import Pagination from "../components/pagination"

import ShapeBlog from "../components/shapes/ShapeBlog"
import LazyLoad from "react-lazyload"

class Blog extends Component {
  state = {}
  render() {
    const {
      group,
      pageCount,
      pathPrefix,
      first,
      last,
      index,
    } = this.props.pageContext

    const pagination = { pageCount, first, last, index, pathPrefix }

    return (
      <Layout>
        <SEO title="Blog" />

        <main className="blog_page">
          <ShapeBlog />

          <div className="container-fluid">
            <div className="page_header_wrapper">
              <h1 className="page_header" data-text="&#60;B4og/&#62;">
                &#60;B4og/&#62;
              </h1>
            </div>

            <div className="row">
              {group.map(({ node }) => {
                return (
                  <div className="col-lg-4 col-sm-6" key={node.id}>
                    <div className="blog_post">
                      <Link to={node.slug}>
                        <div className="thumb_wrapper">
                          <LazyLoad once height={400}>
                            <div
                              className="thumb"
                              style={{
                                backgroundImage: `url(${
                                  node.better_featured_image
                                    ? node.better_featured_image.source_url
                                    : ""
                                })`,
                              }}
                            ></div>
                          </LazyLoad>
                        </div>
                      </Link>
                      <Link to={node.slug}>
                        <p dangerouslySetInnerHTML={{ __html: node.title }} />
                      </Link>
                      <Link to={node.slug} className="btn_custom">
                        &#60;Więcej/&#62;
                      </Link>
                    </div>
                  </div>
                )
              })}
            </div>

            <Pagination {...pagination} />
          </div>
        </main>
      </Layout>
    )
  }
}

export default Blog
